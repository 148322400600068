import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeLabel, isEmpty} from "@core/utils/filter";

export default function datacentersalesUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: '#', label: '#'},
    { key: 'dc_sales_id', label: 'ID', sortable: true },
    // { key: 'store_company_id', label: '平台', sortable: true },
    { key: 'sales_name', label: '销售场次', sortable: true },
    { key: 'type_id', label: '业务类型', sortable: true },
    // { key: 'store_id', label: '店铺Id', sortable: true },
    { key: 'store_name', label: '店铺', sortable: true },
    { key: 'product_code', label: '商品编码', sortable: true },
    { key: 'product_name', label: '商品名称', sortable: true },
    // { key: 'price', label: '供货价', sortable: true },
    { key: 'price', label: '供货价', sortable: true },
    // { key: 'out_name', label: '外部商品名称', sortable: true },
    // { key: 'out_id', label: '外部商品ID', sortable: true },
    { key: 'wh_name', label: '仓库名称', sortable: true },
    { key: 'area_name', label: '卖场名称', sortable: true },
    { key: 'sell_unit_cnt', label: '规格数量', sortable: true },
    // { key: 'sell_unit_name', label: '规格', sortable: true },
    { key: 'sell_total', label: '销量(规格)', sortable: true },
    { key: 'sell_unit_total', label: '销量(单位)', sortable: true },
    // { key: 'outbound_total', label: '出库数量', sortable: true },
    { key: 'is_create_stock', label: '中间表', sortable: true },
    { key: 'nocreate_reason', label: '关联结果', sortable: true },
    // { key: 'expct_pick_time', label: '提货日期', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'memo', label: '备注', sortable: true },
    { key: 'is_create_salesorder', label: '生成销售单', sortable: true },
    { key: 'order_no', label: '销售编号'},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100,1000]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('dc_sales_id')
  const isSortDirDesc = ref(true)
  const click = ref(0)

  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  // watch([start, limit, searchKey,companyid,product_name,product_id,out_name,out_id,sales_name,wh_name], () => {
  //   refetchData()
  // })

  watch([start, limit, click], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const storeArrays = []
    const warehouseArrays = []
    const storeArray = isEmpty(condition.value.storeArray)?[]:condition.value.storeArray
    const warehouseArray = isEmpty(condition.value.warehouseArray)?[]:condition.value.warehouseArray
    for (let i = 0; i < storeArray.length; i++) {
      storeArrays.push(storeArray[i].value)
    }
    for (let i = 0; i < warehouseArray.length; i++) {
      warehouseArrays.push("'"+warehouseArray[i].label+"'")
    }
    store
      .dispatch('datacentersales/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        companyid:condition.value.companyid==null?null:condition.value.companyid.companyid,
        productname:condition.value.product_name,
        productid:condition.value.product_id,
        outname:condition.value.out_name,
        outid:condition.value.out_id,
        salesname:condition.value.sales_name,
        isCreateStock:condition.value.is_create_stock==null?null:condition.value.is_create_stock.code,
        nocreate_reason:condition.value.reason==null?null:condition.value.reason.value,
        code:condition.value.code,
        createTime:condition.value.create_time,
        batchno:condition.value.batchno,
        typeId:condition.value.type_id==null?null:condition.value.type_id.value,
        whname:warehouseArrays.join(","),
        storeId:storeArrays.join(","),
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        const result = data.ext.price
        listTotals.value = data.ext.totals
        for (let i = 0; i < result.length; i++) {
          list[i].priceString = result[i]
          if (list[i].product_id==null){
            list[i].productIsNull = false
          }else {
            list[i].productIsNull = true
          }
        }
        // for (let i = 0; i < list.length; i++) {
        //   const warehouseIds = list[i].ext.warehouseIds.split(",")
        //   var warehouse = ""
        //   for (let j = 0; j < warehouseIds.length; j++) {
        //     warehouse+= getCodeLabel("warehouse",warehouseIds[j])+","
        //   }
        //   list[i].warehouse = warehouse
        // }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '场次销售数据列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    click,
    refetchData,
    condition,

    // Extra Filters
  }
}
