<template>

  <div>

    <b-card header="筛选条件">
      <b-row>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台"
              label-for="net_weight"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="weight_unit"
                :options="companyArray"
                :clearable="true"
                v-model="condition.companyid"
                class="select-size-sm"
                placeholder="请选择平台"
                @input="changeSelect($event,'channel')"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓库"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="warehouseArray"
                :clearable="true"
                v-model="condition.warehouseArray"
                class="select-size-sm"
                placeholder="请选择平台"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                @input="function() {
                 $forceUpdate()
                }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺"
              label-for="store"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="store"
                :options="storeArray"
                v-model="condition.storeArray"
                :clearable="true"
                class="select-size-sm"
                placeholder="请选择店铺"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                @input="function() {
                 $forceUpdate()
                }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="我方产品名称"
              label-for="height"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="condition.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="我方产品ID"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="condition.product_id"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="对方产品名称"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="condition.out_name"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="对方产品ID"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="condition.out_id"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="场次"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <flat-pickr
                v-model="condition.sales_name"
                class="form-control"
                :config="{ mode: 'range'}"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否生成中间表"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="is"
                v-model="condition.is_create_stock"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="关联结果"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="getCodeOptions('datacenter-sales-reason')"
                v-model="condition.reason"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="69码"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="condition.code"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="添加时间"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <flat-pickr
                v-model="condition.create_time"
                class="form-control"
                :config="{ mode: 'range'}"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="excel编码"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="condition.batchno"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="业务类型"
              label-for="length"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="getCodeOptions('business_type')"
                v-model="condition.type_id"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">

        </b-col>
        <b-col md="10">

        </b-col>

        <b-col
            cols="2"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="search()"
              v-model="click"
          >
            搜索
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="clean()"
          >
            清除
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="reload()"
          >
            清空勾选
          </b-button>
        </b-col>
      </b-row>


    </b-card>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                @click="advanced_search_excel"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">导入</span>
            </b-button>

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="8"
          >

            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="createExcel"
              >
                生成excel
              </b-button>

              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="batchCreate"
                  v-if="showDSS"
              >
                批量生成中间表
              </b-button>

              <b-button
                  variant="primary"
                  class="mr-1"
                  v-b-modal.time
                  v-if="showSalesOrder"
              >
                生成销售单(按天和搜索条件)
              </b-button>

              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="batchCreateSalesOrder"
                  v-if="showSalesOrder"
              >
                生成销售单(按勾选)
              </b-button>
              <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  v-b-modal.delete
                  v-if="user.role_id==14"
                  class="mr-1"
              >
                <span class="align-middle">删除数据</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="batchDelete"
                  v-if="user.role_id==14"
              >
                <span class="align-middle">批量删除</span>
              </b-button>

              <!--              <b-form-input-->
              <!--                  v-model="searchKey"-->
              <!--                  class="d-inline-block mr-1"-->
              <!--                  placeholder="搜索关键字..."-->
              <!--              />-->
              <!--              <b-button-->
              <!--                variant="primary"-->
              <!--                :to="{ name: 'apps-datacentersales-edit'}"-->
              <!--              >-->
              <!--                <span class="text-nowrap">添加</span>-->
              <!--              </b-button>-->
            </div>
          </b-col>
        </b-row>
        <!--excelY-->
        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <!--平台选择-->
              <b-col
                  cols="12"
                  md="4"
                  v-if="false"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="平台选择:"
                    label-for="channel_name"
                    label-size="sm"
                    class="required"
                >
                  <b-form-input
                      id="company_id_excel"
                      size="sm"
                      v-model="companyNameExcel"
                      name="company_id_excel"
                      @click="showSingleModal('平台')"
                      readonly
                      placeholder="点击搜索平台"
                  />
                </b-form-group>
              </b-col>

              <!--店铺-->
              <b-col
                  cols="12"
                  md="6"
                  v-if="false"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺选择:"
                    label-for="store_name"
                    label-size="sm"
                    class="required"
                >
                  <b-form-input
                      id="store_Name"
                      size="sm"
                      v-model="storeName"
                      name="storeName"
                      @click="showSingleModal('店铺')"
                      readonly
                      placeholder="点击搜索店铺"
                  />
                </b-form-group>
              </b-col>
              <!--excel-->

              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="importOffSalesOrder"
                    v-if="show"
                >
                  <span class="align-right">线下销售导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
          striped
          hover
          table-variant="warning"
          head-variant="light"
      >

        <template v-slot:head(#)="data">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allchecked"
              :indeterminate="indeterminate"
              :isCheckedObj.sync="isCheckedObj"
              :table="table"
              type="head"
              :row="data"
          />
        </template>
        <template v-slot:cell(#)="row">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allchecked"
              :indeterminate="indeterminate"
              :isCheckedObj="isCheckedObj"
              :table="table"
              type="row"
              :row="row"
              :rowChecked.sync="isCheckedObj['index'+row.item.id]"
          />
        </template>

        <!-- Columns -->
        <template #cell(dc_sales_id)="data">
          <div style="width: 80px"></div>
          #{{ data.item.dc_sales_id }}
          <feather-icon
              icon="EyeIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+'添加时间：'+toTime(data.item.create_time)+
              '<br>'+'excel编码：'+data.item.batchno+
              '</div>'"
              variant="success"
          />
        </template>


        <!-- Columns -->
        <template #cell(price)="data">
          {{ (data.item.price != null ? data.item.price.toFixed(4) : "无数据") }}
        </template>

        <template #cell(type_id)="data">
          {{ getCodeLabel("business_type", data.item.type_id) }}
        </template>


        <template #cell(order_no)="data">
          <b-link
              :to="{ name: 'apps-salesorder-edit', query: { id: data.item.ext.orderId },params:{readonly:1} }"
              target="_blank"
          >
            {{ data.item.ext.orderNo }}
          </b-link>

        </template>

        <!-- Columns -->
        <template #cell(product_code)="data">
          {{ data.item.product_code }}
        </template>

        <template #cell(store_name)="data">
          <div style="width: 150px">
          </div>
          {{ data.item.type_id == 5 ? '终端客户' : '平台' }}:#[{{
            data.item.store_company_id
          }}]{{ getCodeLabel("company", data.item.store_company_id) }}
          <br>
          店铺:#[{{ data.item.store_id }}]{{ data.item.store_name }}
        </template>

        <template #cell(product_name)="data">
          行云：#{{ data.item.product_id }}{{ data.item.product_name }}
          <br>
          外部：#{{ data.item.out_id }}{{ data.item.out_name }}
        </template>

        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <template #cell(sell_unit_cnt)="data">
          {{ data.item.sell_unit_cnt }}/{{ data.item.sell_unit_name }}
        </template>

        <template #cell(store_company_id)="data">
          {{ getCodeLabel("company", data.item.store_company_id) }}
        </template>

        <template #cell(wh_name)="data">
          {{ data.item.wh_name }}
          <feather-icon
              icon="EyeIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+'我方仓库：'+data.item.ext.warehouseNames+
              '<br>'+'销售权：'+data.item.ext.purchaseTeamNames+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(memo)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(product_id)="data">
          <b-form-input type="number"
                        v-model="data.item.product_id"
                        :readonly="data.item.is_create_stock!=0&&data.item.is_create_stock!=null"
          >
          </b-form-input>
        </template>


        <!-- Column: Type -->
        <template #cell(is_create_stock)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_create_stock==0||data.item.is_create_stock==null"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(is_create_salesorder)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_create_salesorder==0||data.item.is_create_salesorder==null"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="saveSalesOrderItem(data.item)"
                             v-if="data.item.is_create_stock==0||data.item.is_create_stock==null">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">保存</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.is_create_stock==0||data.item.is_create_stock==null"
                             @click="createDatacenterSalesStock(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">生成中间表</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.is_create_stock==1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.is_create_stock==0&&data.item.nocreate_reason=='生成中间表数量为0,关联失败'" @click="showStockOrder(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">占用库存单据</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
        <b-modal
            id="time"
            ok-only
            ok-title="确认"
            @ok="createSalesOrder"
            cancel-title="取消"
            centered
            size="lg"
            title="弹窗"
            ref="timeModel"
        >
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售场次:"
                  label-for="sales_time"
                  label-size="sm"
                  class="required"
              >
                <flat-pickr
                    v-model="salesTime"
                    class="form-control"
                    placeholder="请选择销售场次"
                />
              </b-form-group>

            </b-col>
          </b-row>
        </b-modal>

        <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="修改备注"
            ref="remarkModal"
        >
          <b-card :header="`编号：${selectedItem.dc_sales_id}`">
            <b-form-textarea
                id="textarea"
                v-model="remarkInfo"
                rows="3"
                max-rows="6"
            />
          </b-card>
        </b-modal>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName ==='平台' "
          >
          </company-select>


          <store-list-select
              ref="storeSelect" v-else-if="modalName === '店铺' "
          >
          </store-list-select>


        </b-modal>

        <b-modal
            id="delete"
            ok-only
            ok-title="确认"
            @ok="deleteData()"
            cancel-title="取消"
            centered
            size="lg"
            title="删除窗口"
            ref="delete"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="excel编码:"
              label-for="channel_order_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="batchno"
                size="sm"
                v-model="batchnos"
                placeholder="请输入excel编码"
            />
          </b-form-group>
        </b-modal>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import datacentersalesUseList from './datacentersalesUseList'
import datacentersalesStore from './datacentersalesStore'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import companyStore from "@/views/apps/company/companyStore";
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Button from "@/views/components/button/Button";
import {useToast} from "vue-toastification/composition";
import StoreListSelect from '@/views/apps/store/StoreListSelect';
import CompanySelect from "../company/CompanySelect";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {getUserData} from "@/auth/utils";
import axios from "@/libs/axios";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
import warehouserelationStore from "@/views/apps/warehouserelation/warehouserelationStore";
import storeStore from "@/views/apps/store/storeStore";

export default {
  components: {
    XyCheckbox,
    CompanySelect,
    StoreListSelect,
    AttachmentUpload,
    Button,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      companyArray: [],
      warehouseArray: [],
      storeArray: [],
      // companyid: "",
      // product_name: "",
      // product_id: "",
      // out_name: "",
      // out_id: "",
      // sales_name: "",
      // wh_name: "",
      // click: 0,
      is: [{"label": '否', code: 0}, {"label": '是', code: 1}],
      salesTime: '',
      showSalesOrder: true,
      showDSS: true,
      selectedItem: {},
      remarkInfo: null,

      advanced_search_modal_excel: false,
      companyNameExcel: '',
      storeName: '',
      show: true,
      modalName: '',
      excelSqlId: '',
      storeId: '',
      companyId: '',

      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      user: {},
      batchnos: '',
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('datacentersales/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.memo
      this.$refs['remarkModal'].show()
    },
    showSingleModal(modalName, data) {
      if (modalName === '平台') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '店铺') {
        this.companyType = 'Store'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '平台': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.companyNameExcel = data[0].company_name;
            this.companyId = data[0].company_id;
          }
          break
        }
        case '店铺': {
          data = this.$refs.storeSelect.getSelected()
          if (data.length > 0) {
            this.storeName = data[0].store_name;
            this.storeId = data[0].store_id;
          }
          break;
        }
        default: {

          break
        }
      }
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('datacentersales')) store.registerModule('datacentersales', datacentersalesStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('warehouserelation')) store.registerModule('warehouserelation', warehouserelationStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('datacentersales')) store.unregisterModule('datacentersales')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('warehouserelation')) store.unregisterModule('warehouserelation')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const getCompany = function (params) {
      store
          .dispatch('company/searchChannelCustomer')
          .then(response => {
            const data = response.data.data
            const list = data.ext.list
            for (let i = 0; i < list.length; i++) {
              params.push({"label": list[i].company_name, "companyid": list[i].company_id})
            }
          })
    }

    const edit = function () {
      getCompany(this.companyArray)

      store.dispatch('warehouserelation/search')
          .then(response => {
            const data = response.data.data
            const list = data.ext.list
            for (let i = 0; i < list.length; i++) {
              this.warehouseArray.push({"label": list[i].out_warehouse_name, "value": list[i].wh_id})
            }
          })

      this.storeArray = getCodeOptions('store')
    }

    const search = function () {
      this.click = this.click === 0 ? 1 : 0
      this.reload()
    }

    const clean = function () {
      this.condition = {}
      this.reload()
    }

    /*excelY*/
    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const searchByConditionExcel = function () {
      this.show = false;

      const params = {
        storeId: this.storeId,
        companyId: this.companyId,
        excelSqlId: this.excelSqlId,
      }
      // if (this.companyId == null || this.companyId == "") {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: '请选择平台',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   return
      // } else if (this.storeId === null || this.storeId == "") {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: '请选择店铺',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   return
      // } else {
      //
      // }
      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      store.dispatch('datacentersales/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const createExcel = function () {
      // window.location.href = 'http://dev.localhost:8080/api/datacentersales/test';
      // window.open(process.env.VUE_APP_BASE_URL + '/api/datacentersales/createExcel?type=' + params)
      const storeArrays = []
      const warehouseArrays = []
      const storeArray = isEmpty(condition.value.storeArray)?[]:condition.value.storeArray
      const warehouseArray = isEmpty(condition.value.warehouseArray)?[]:condition.value.warehouseArray
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      for (let i = 0; i < warehouseArray.length; i++) {
        warehouseArrays.push("'"+warehouseArray[i].label+"'")
      }
      axios({
            url: '/api/exportexcelfile/dcCreateExcel',
            method:'get',
            params: {
              companyid:condition.value.companyid==null?null:condition.value.companyid.companyid,
              productname:condition.value.product_name,
              productid:condition.value.product_id,
              outname:condition.value.out_name,
              outid:condition.value.out_id,
              salesname:condition.value.sales_name,
              isCreateStock:condition.value.is_create_stock==null?null:condition.value.is_create_stock.code,
              nocreate_reason:condition.value.reason==null?null:condition.value.reason.value,
              code:condition.value.code,
              createTime:condition.value.create_time,
              batchno:condition.value.batchno,
              whname:warehouseArrays.join(","),
              storeId:storeArrays.join(","),
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "pdd-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const saveSalesOrderItem = function (params) {
      store.dispatch('datacentersales/save', params).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.reload()
        } else {
          toast.error("保存失败!")
        }

      })
    }

    const createDatacenterSalesStock = function (params) {
      if (params.is_create_stock == 1) {
        toast.error("有数据已创建,请勿重复创建!")
        return false
      }
      if (params.product_id == 0 || params.product_id == null) {
        toast.error("请填写商品id!")
        return false
      }

      store.dispatch('datacentersales/createDatacenterSalesStock', {id: params.dc_sales_id}).then(res => {
        if (res.data.code == 0) {
          toast.success('创建成功!')
        } else {
          toast.error(res.data.data)
        }
        this.reload()

      })

    }

    const createSalesOrder = function () {
      if (this.salesTime === "" || this.salesTime === undefined) {
        toast.error("请选择销售场次")
        return false
      }
      this.showSalesOrder = false
      const storeArrays = []
      const warehouseArrays = []
      const storeArray = isEmpty(condition.value.storeArray)?[]:condition.value.storeArray
      const warehouseArray = isEmpty(condition.value.warehouseArray)?[]:condition.value.warehouseArray
      for (let i = 0; i < storeArray.length; i++) {
        storeArrays.push(storeArray[i].value)
      }
      for (let i = 0; i < warehouseArray.length; i++) {
        warehouseArrays.push("'"+warehouseArray[i].label+"'")
      }

      store.dispatch('datacentersales/createSalesOrder', {
        date: this.salesTime,
        companyid:condition.value.companyid==null?null:condition.value.companyid.companyid,
        productname:condition.value.product_name,
        productid:condition.value.product_id,
        outname:condition.value.out_name,
        outid:condition.value.out_id,
        salesname:condition.value.sales_name,
        isCreateStock:condition.value.is_create_stock==null?null:condition.value.is_create_stock.code,
        nocreate_reason:condition.value.reason==null?null:condition.value.reason.value,
        code:condition.value.code,
        createTime:condition.value.create_time,
        batchno:condition.value.batchno,
        typeId:condition.value.type_id==null?null:condition.value.type_id.value,
        whname:warehouseArrays.join(","),
        storeId:storeArrays.join(","),
      }).then(res => {
        if (res.data.code == 0) {
          toast.success('创建销售单成功!')
        } else {
          toast.error(res.data.data)
        }
        this.reload()
        this.showSalesOrder = true

      })

    }

    const saveRemark = function () {
      this.selectedItem.memo = this.remarkInfo
      store.dispatch('datacentersales/save', this.selectedItem).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
        this.reload()
      })
    }

    const batchCreate = function () {
      const check = this.checked
      this.showDSS = false
      if (check.length == 0) {
        toast.error("请选择数据!")
        this.showDSS = true
        return false
      }
      for (let i = 0; i < check.length; i++) {
        if (check[i].is_create_stock == 1) {
          toast.error("当前数据已创建,请勿重复创建!")
          this.showDSS = true
          return false
        }
        if (check[i].product_id == 0 || check[i].product_id == null) {
          toast.error("请填写商品id!")
          this.showDSS = true
          return false
        }
      }
      store.dispatch('datacentersales/batchCreateDatacenterSalesStock', {check: JSON.stringify(check)}).then(res => {
        if (res.data.code == 0) {
          toast.success('成功!')
          this.showDSS = true
        } else {
          this.showDSS = true
          toast.error(res.data.data)
        }
        this.reload()
      })
    }

    const batchCreateSalesOrder = function () {
      if (confirm("是否将"+this.checked.length+"条数据创建销售单？")){
        const check = this.checked
        this.showSalesOrder = false
        const idArray = []
        if (check.length == 0) {
          toast.error("请选择数据!")
          this.showSalesOrder = true
          return false
        }
        for (let i = 0; i < check.length; i++) {
          if (check[i].product_id == 0 || check[i].product_id == null) {
            toast.error("请填写商品id!")
            this.showSalesOrder = true
            return false
          }
          idArray.push(check[i].dc_sales_id)
        }
        const ids = idArray.join(",")
        store.dispatch('datacentersales/batchCreateSalesOrder', {ids:ids}).then(res => {
          if (res.data.code == 0) {
            toast.success('创建销售单成功!')
          } else {
            toast.error(res.data.data)
          }
          this.reload()
          this.showSalesOrder = true
        })
      }
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked = []
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const deleteData = function () {
      if (this.batchnos == "" || this.batchnos == undefined) {
        toast.error("请输入excel编码")
        return false
      }
      if (confirm("确定删除吗?")) {
        store.dispatch('datacentersales/delete', {batchno: this.batchnos}).then(res => {
          if (res.data.code === 0) {
            toast.success("删除成功")
          } else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }
    }

    const batchDelete = function () {
      if (this.checked.length === 0) {
        toast.error("请选择销售数据!")
        return false
      }
      if (confirm("确定批量删除吗?")) {
        store.dispatch('datacentersales/batchDelete', {checked: JSON.stringify(this.checked)}).then(res => {
          if (res.data.code == 0) {
            toast.success("删除成功")
          } else {
            toast.error(res.data.data)
          }
          this.reload()
        })
      }
    }

    const importOffSalesOrder = function () {
      this.show = false;

      const params = {
        storeId: this.storeId,
        excelSqlId: this.excelSqlId,
      }
      // if (this.storeId === null || this.storeId == "") {
      //   toast.error("请选择店铺")
      //   this.show = true
      //   return false
      // }
      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      store.dispatch('datacentersales/exportOSO', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const showStockOrder = function (params) {
      store.dispatch('datacentersales/getStockNo', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              alert(data)
            } else {
              toast.error("查询失败!")
            }
          })
    }

    const changeSelect = function (event,params) {
      if (params === 'channel'){
        this.warehouseArray = []
        this.storeArray = []
        this.condition.storeArray = []
        this.condition.warehouseArray = []
        store.dispatch('store/search',{company_id:isEmpty(this.condition.companyid)?null:this.condition.companyid.companyid})
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.storeArray.push({'key':i,"label":data[i].store_name,"value":data[i].store_id})
              }
            })
        store.dispatch('warehouserelation/search',{store_brand_id:isEmpty(this.condition.companyid)?null:this.condition.companyid.companyid})
            .then(response => {
              let data = response.data.data.list
              for (let i = 0; i < data.length; i++) {
                this.warehouseArray.push({'key':i,"label":data[i].out_warehouse_name,"value":data[i].wh_id})
              }
            })
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      click,
      refetchData,
      condition,

      // UI
    } = datacentersalesUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      click,
      refetchData,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      getCompany,
      edit,
      search,
      clean,
      toTime,
      createExcel,
      saveSalesOrderItem,
      createDatacenterSalesStock,
      createSalesOrder,
      saveRemark,
      batchCreate,
      reload,
      advanced_search_excel,
      onUploaded,
      searchByConditionExcel,
      deleteData,
      batchDelete,
      importOffSalesOrder,
      showStockOrder,
      batchCreateSalesOrder,
      changeSelect,
    }
  },
  created() {
    this.edit()
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
